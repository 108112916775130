var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c(
          "vue-tabs",
          [
            _c("v-tab", { attrs: { title: "Thông tin chung" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-8 col-md-8 col-sm-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Tên cuộc đua"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              class: _vm.errors.has("vi_title")
                                ? "border-danger"
                                : "",
                              attrs: {
                                placeholder: "Tên cuộc đua",
                                "data-vv-name": "vi_title",
                                "data-vv-as": "Tên cuộc đua",
                              },
                              model: {
                                value: _vm.form.vi_title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vi_title", $$v)
                                },
                                expression: "form.vi_title",
                              },
                            }),
                            _vm.errors.has("vi_title")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errors.first("vi_title"))),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Tên cuộc đua (en)"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              class: _vm.errors.has("en_title")
                                ? "border-danger"
                                : "",
                              attrs: {
                                placeholder: "Tên cuộc đua (en)",
                                "data-vv-name": "en_title",
                                "data-vv-as": "Tên cuộc đua (en)",
                              },
                              model: {
                                value: _vm.form.en_title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "en_title", $$v)
                                },
                                expression: "form.en_title",
                              },
                            }),
                            _vm.errors.has("en_title")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errors.first("en_title"))),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("SKU")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "SKU" },
                              model: {
                                value: _vm.form.sku,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sku", $$v)
                                },
                                expression: "form.sku",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Đường dẫn"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c(
                              "el-input",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                class: _vm.errors.has("slug")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  placeholder: "duong-dan",
                                  "data-vv-name": "slug",
                                  "data-vv-as": "Đường dẫn",
                                },
                                model: {
                                  value: _vm.form.slug,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "slug", $$v)
                                  },
                                  expression: "form.slug",
                                },
                              },
                              [
                                _c("template", { slot: "prepend" }, [
                                  _vm._v("https://irace.vn/races/"),
                                ]),
                              ],
                              2
                            ),
                            _vm.errors.has("slug")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errors.first("slug"))),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Địa điểm"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              class: _vm.errors.has("vi_address")
                                ? "border-danger"
                                : "",
                              attrs: {
                                placeholder: "Địa điểm",
                                "data-vv-name": "vi_address",
                                "data-vv-as": "Địa điểm",
                              },
                              model: {
                                value: _vm.form.vi_address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vi_address", $$v)
                                },
                                expression: "form.vi_address",
                              },
                            }),
                            _vm.errors.has("vi_address")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("vi_address"))
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Địa điểm (en)"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              class: _vm.errors.has("en_address")
                                ? "border-danger"
                                : "",
                              attrs: {
                                placeholder: "Địa điểm (en)",
                                "data-vv-name": "en_address",
                                "data-vv-as": "Địa điểm (en)",
                              },
                              model: {
                                value: _vm.form.en_address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "en_address", $$v)
                                },
                                expression: "form.en_address",
                              },
                            }),
                            _vm.errors.has("en_address")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("en_address"))
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Kiểu"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("my-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "full-width",
                              class: _vm.errors.has("type_id")
                                ? "border-danger"
                                : "",
                              attrs: {
                                attribute: _vm.raceTypes,
                                placeholder: "Chọn kiểu...",
                                "data-vv-name": "type_id",
                                "data-vv-as": "Kiểu",
                              },
                              model: {
                                value: _vm.form.type_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type_id", $$v)
                                },
                                expression: "form.type_id",
                              },
                            }),
                            _vm.errors.has("type_id")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errors.first("type_id"))),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Thể loại"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("my-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "full-width",
                              class: _vm.errors.has("category_id")
                                ? "border-danger"
                                : "",
                              attrs: {
                                attribute: _vm.raceCategories,
                                placeholder: "Chọn thể loại...",
                                "data-vv-name": "category_id",
                                "data-vv-as": "Thể loại",
                              },
                              model: {
                                value: _vm.form.category_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "category_id", $$v)
                                },
                                expression: "form.category_id",
                              },
                            }),
                            _vm.errors.has("category_id")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("category_id"))
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm.form.category_id == 4
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                              [
                                _c("b", { staticClass: "required" }, [
                                  _vm._v("Số ngày"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "Số ngày",
                                  },
                                  model: {
                                    value: _vm.form.days,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "days", $$v)
                                    },
                                    expression: "form.days",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.form.category_id == 4
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                              [_c("b", [_vm._v("Race trước")])]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                              [
                                _c("my-select", {
                                  staticClass: "full-width",
                                  attrs: {
                                    attribute: _vm.races,
                                    placeholder: "Chọn race...",
                                  },
                                  model: {
                                    value: _vm.form.required_completed_race,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "required_completed_race",
                                        $$v
                                      )
                                    },
                                    expression: "form.required_completed_race",
                                  },
                                }),
                                _c(
                                  "i",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "red",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "* Sau khi hoàn thành race này thì mới đăng ký được"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.form.category_id == 3
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                              [_c("b", [_vm._v("Link đối tác")])]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "Link đối tác" },
                                  model: {
                                    value: _vm.form.partner_link,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "partner_link", $$v)
                                    },
                                    expression: "form.partner_link",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Không chọn cự ly")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_without_catalog,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_without_catalog", $$v)
                                },
                                expression: "form.is_without_catalog",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.form.is_without_catalog == 0
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                              [
                                _c("b", { staticClass: "required" }, [
                                  _vm._v("Cự ly"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                              [
                                _c("my-select", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate.disable",
                                      value: "required",
                                      expression: "'required'",
                                      modifiers: { disable: true },
                                    },
                                  ],
                                  staticClass: "full-width",
                                  class: _vm.errors.has("catalogs")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    attribute: _vm.raceCatalogs,
                                    placeholder: "Chọn nhiều cự ly...",
                                    "data-vv-name": "catalogs",
                                    "data-vv-as": "Cự ly",
                                  },
                                  model: {
                                    value: _vm.form.catalogs,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "catalogs", $$v)
                                    },
                                    expression: "form.catalogs",
                                  },
                                }),
                                _vm.errors.has("catalogs")
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("catalogs"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Chủ đề")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.raceThemes,
                                placeholder: "Chọn nhiều chủ đề...",
                              },
                              model: {
                                value: _vm.form.themes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "themes", $$v)
                                },
                                expression: "form.themes",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Đăng ký tối đa"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min_value:0",
                                  expression: "'required|min_value:0'",
                                },
                              ],
                              class: _vm.errors.has("price")
                                ? "border-danger"
                                : "",
                              attrs: {
                                type: "number",
                                placeholder: "Đăng ký tối đa",
                                "data-vv-name": "limit",
                                "data-vv-as": "Đăng ký tối đa",
                              },
                              model: {
                                value: _vm.form.limit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "limit", $$v)
                                },
                                expression: "form.limit",
                              },
                            }),
                            _vm.errors.has("limit")
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(_vm.errors.first("limit"))),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Người đăng ký ảo")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "number",
                                placeholder: "Người chơi ảo",
                              },
                              model: {
                                value: _vm.form.virtual_user,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "virtual_user", $$v)
                                },
                                expression: "form.virtual_user",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Miễn phí vận chuyển")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_free_ship,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_free_ship", $$v)
                                },
                                expression: "form.is_free_ship",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm.form.is_free_ship == 0
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                              [
                                _c("b", { staticClass: "required" }, [
                                  _vm._v("Phí vận chuyển"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|min_value:1",
                                      expression: "'required|min_value:1'",
                                    },
                                  ],
                                  class: _vm.errors.has("shipping_fee")
                                    ? "border-danger"
                                    : "",
                                  attrs: {
                                    placeholder: "Phí vận chuyển",
                                    type: "number",
                                    "data-vv-name": "shipping_fee",
                                    "data-vv-as": "Phí vận chuyển",
                                  },
                                  model: {
                                    value: _vm.form.shipping_fee,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "shipping_fee", $$v)
                                    },
                                    expression: "form.shipping_fee",
                                  },
                                }),
                                _vm.errors.has("shipping_fee")
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("shipping_fee"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { display: "none" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                            [_c("b", [_vm._v("Hashtag")])]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                            [
                              _c("my-tag", {
                                model: {
                                  value: _vm.form.tag,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "tag", $$v)
                                  },
                                  expression: "form.tag",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-lg-4 col-md-4 col-sm-12 sidebar-right" },
                  [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body form-card" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("b", { staticClass: "required" }, [
                              _vm._v("Thời gian kết thúc khắc tên"),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-date-picker", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "full-width",
                                class: _vm.errors.has("engraved_at")
                                  ? "border-danger"
                                  : "",
                                attrs: {
                                  "value-format": "yyyy/MM/dd H:m:s",
                                  type: "datetime",
                                  placeholder: "Chọn ngày giờ",
                                  "data-vv-name": "engraved_at",
                                  "data-vv-as": "Thời gian kết thúc khắc tên",
                                },
                                model: {
                                  value: _vm.form.engraved_at,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "engraved_at", $$v)
                                  },
                                  expression: "form.engraved_at",
                                },
                              }),
                              _vm.errors.has("engraved_at")
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("engraved_at"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm.form.category_id != 4
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("b", { staticClass: "required" }, [
                                  _vm._v("Thời gian kết thúc đăng ký"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-sm-12" },
                                [
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    class: _vm.errors.has("register_at")
                                      ? "border-danger"
                                      : "",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày giờ",
                                      "data-vv-name": "register_at",
                                      "data-vv-as":
                                        "Thời gian kết thúc đăng ký",
                                    },
                                    model: {
                                      value: _vm.form.register_at,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "register_at", $$v)
                                      },
                                      expression: "form.register_at",
                                    },
                                  }),
                                  _vm.errors.has("register_at")
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first("register_at")
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.form.category_id != 4
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("b", { staticClass: "required" }, [
                                  _vm._v("Thời gian bắt đầu"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-sm-12" },
                                [
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    class: _vm.errors.has("start_at")
                                      ? "border-danger"
                                      : "",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày giờ",
                                      "data-vv-name": "start_at",
                                      "data-vv-as": "Thời gian bắt đầu",
                                    },
                                    model: {
                                      value: _vm.form.start_at,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "start_at", $$v)
                                      },
                                      expression: "form.start_at",
                                    },
                                  }),
                                  _vm.errors.has("start_at")
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.errors.first("start_at"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.form.category_id != 4
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("b", { staticClass: "required" }, [
                                  _vm._v("Thời gian kết thúc"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-sm-12" },
                                [
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|earlier",
                                        expression: "'required|earlier'",
                                      },
                                    ],
                                    staticClass: "full-width",
                                    class: _vm.errors.has("end_at")
                                      ? "border-danger"
                                      : "",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày giờ",
                                      "data-vv-name": "end_at",
                                      "data-vv-as": "Thời gian kết thúc",
                                    },
                                    model: {
                                      value: _vm.form.end_at,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "end_at", $$v)
                                      },
                                      expression: "form.end_at",
                                    },
                                  }),
                                  _vm.errors.has("end_at")
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.errors.first("end_at"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [_vm._v("Cut off time")]),
                            _c("el-input", {
                              attrs: { placeholder: "Nhập số phút" },
                              model: {
                                value: _vm.form.cut_off_time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "cut_off_time", $$v)
                                },
                                expression: "form.cut_off_time",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("b", [_vm._v("Nổi bật")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.hot,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "hot", $$v)
                                  },
                                  expression: "form.hot",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("b", [_vm._v("Hoàn thành trong 1 lần")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.is_one_lap,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_one_lap", $$v)
                                  },
                                  expression: "form.is_one_lap",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("b", [_vm._v("Unlisted")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-sm-12" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.form.unlisted,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "unlisted", $$v)
                                  },
                                  expression: "form.unlisted",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("v-tab", { attrs: { title: "Combo" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Combo")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_combo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_combo", $$v)
                                },
                                expression: "form.is_combo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.is_combo,
                              expression: "form.is_combo",
                            },
                          ],
                          staticClass: "row",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                            [_c("b", [_vm._v("Chọn race")])]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-md-10" },
                            [
                              _c("my-select", {
                                staticClass: "full-width",
                                attrs: {
                                  attribute: _vm.races,
                                  multiple: true,
                                  placeholder: "Chọn race...",
                                },
                                model: {
                                  value: _vm.form.race_ids,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "race_ids", $$v)
                                  },
                                  expression: "form.race_ids",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.form.race_ids && _vm.form.race_ids.length > 1
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                              [_c("b", [_vm._v("Giảm giá")])]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-10" },
                              _vm._l(_vm.form.race_ids, function (item, index) {
                                return _c("div", [
                                  index < _vm.form.race_ids.length - 1
                                    ? _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          _vm._v(
                                            "Giảm giá khi mua " +
                                              _vm._s(index + 2) +
                                              " race (%):"
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-8" },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "5%",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.combo_discounts[
                                                    index + 2
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.combo_discounts,
                                                    index + 2,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "combo_discounts[index + 2]",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("v-tab", { attrs: { title: "Giá" } }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body form-card" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12 col-lg-2 col-md-2" }, [
                      _c("b", [_vm._v("Miễn phí")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                      [
                        _c("el-switch", {
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          model: {
                            value: _vm.form.is_free,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "is_free", $$v)
                            },
                            expression: "form.is_free",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.is_free == 0
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _c("b", [_vm._v("Kiểu giá")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-10" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.prices.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.prices, "type", $$v)
                                  },
                                  expression: "prices.type",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "one" } },
                                  [_vm._v("Một giá")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "many" } },
                                  [_vm._v("Nhiều giá")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "catalog" } },
                                  [_vm._v("Giá theo cự ly")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.prices.type == "one",
                      expression: "prices.type == 'one'",
                    },
                  ],
                  staticClass: "card",
                },
                [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("b", { staticClass: "catalog-title" }, [
                          _vm._v("Một giá"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _vm._v("Giá bán"),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Giá bán",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.prices.one.standard,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.one,
                                          "standard",
                                          $$v
                                        )
                                      },
                                      expression: "prices.one.standard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c("el-col", { attrs: { span: 6 } }, [
                                _vm._v("Giá so sánh"),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Giá so sánh",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.prices.one.compare,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.prices.one, "compare", $$v)
                                      },
                                      expression: "prices.one.compare",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.prices.type == "many",
                      expression: "prices.type == 'many'",
                    },
                  ],
                  staticClass: "card",
                },
                [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("b", { staticClass: "catalog-title" }, [
                          _vm._v("Nhiều giá"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v("Super early bird"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Giá Super early bird",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.prices.many.super_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "super_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.many.super_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày bắt đầu",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.many
                                          .start_at_super_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "start_at_super_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.many.start_at_super_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày kết thúc",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.many.end_at_super_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "end_at_super_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.many.end_at_super_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v("Early bird"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Giá Early bird",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.prices.many.early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "early_bird",
                                          $$v
                                        )
                                      },
                                      expression: "prices.many.early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày bắt đầu",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.many.start_at_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "start_at_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.many.start_at_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày kết thúc",
                                    },
                                    model: {
                                      value: _vm.prices.many.end_at_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "end_at_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.many.end_at_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [_vm._v("Standard")]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "Giá Standard",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.prices.many.standard,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.many,
                                          "standard",
                                          $$v
                                        )
                                      },
                                      expression: "prices.many.standard",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.prices.type == "catalog",
                      expression: "prices.type == 'catalog'",
                    },
                  ],
                  staticClass: "card",
                },
                [
                  _c("div", { staticClass: "card-body form-card" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("b", { staticClass: "catalog-title" }, [
                          _vm._v("Giá theo cự ly"),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v("Chọn cự ly"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-7" },
                        [
                          _c("my-select", {
                            staticClass: "full-width",
                            attrs: {
                              attribute: _vm.raceCatalogs,
                              placeholder: "Chọn nhiều cự ly...",
                            },
                            model: {
                              value: _vm.form.catalogs,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "catalogs", $$v)
                              },
                              expression: "form.catalogs",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v("Super Early Bird"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày bắt đầu",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.catalog
                                          .start_at_super_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.catalog,
                                          "start_at_super_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.catalog.start_at_super_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày kết thúc",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.catalog
                                          .end_at_super_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.catalog,
                                          "end_at_super_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.catalog.end_at_super_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-2" }, [
                        _vm._v("Early Bird"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày bắt đầu",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.catalog.start_at_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.catalog,
                                          "start_at_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.catalog.start_at_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "full-width",
                                    attrs: {
                                      "value-format": "yyyy/MM/dd H:m:s",
                                      type: "datetime",
                                      placeholder: "Chọn ngày kết thúc",
                                    },
                                    model: {
                                      value:
                                        _vm.prices.catalog.end_at_early_bird,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.prices.catalog,
                                          "end_at_early_bird",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "prices.catalog.end_at_early_bird",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("hr"),
                    _vm.catalogSelectedPrice && _vm.catalogSelectedPrice.length
                      ? _c(
                          "div",
                          { staticClass: "list-price-catalog" },
                          _vm._l(_vm.catalogSelectedPrice, function (item) {
                            return _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-2" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-10" },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Super Early Bird",
                                              type: "number",
                                            },
                                            model: {
                                              value: item.super_early_bird,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "super_early_bird",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.super_early_bird",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Early Bird",
                                              type: "number",
                                            },
                                            model: {
                                              value: item.early_bird,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "early_bird",
                                                  $$v
                                                )
                                              },
                                              expression: "item.early_bird",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "Standard",
                                              type: "number",
                                            },
                                            model: {
                                              value: item.standard,
                                              callback: function ($$v) {
                                                _vm.$set(item, "standard", $$v)
                                              },
                                              expression: "item.standard",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]),
            _c("v-tab", { attrs: { title: "Hình ảnh" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Ảnh cover")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("my-image", {
                              model: {
                                value: _vm.form.image,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "image", $$v)
                                },
                                expression: "form.image",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", [_vm._v("Huy chương (Medal)")]),
                            _c("br"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.window.SERVER_BASE_URL +
                                    "/images/medal-01.png",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Tải template 01")]
                            ),
                            _c("br"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.window.SERVER_BASE_URL +
                                    "/images/medal-02.png",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Tải template 02")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-sm-12 col-lg-6 col-md-6 p-l-0",
                                },
                                [
                                  _c("my-image", {
                                    model: {
                                      value: _vm.form.medal,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "medal", $$v)
                                      },
                                      expression: "form.medal",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-sm-12 col-lg-6 col-md-6" },
                                [
                                  _c("canvas", {
                                    ref: "canvas-medal-deactive",
                                    staticStyle: { "max-width": "300px" },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", [_vm._v("Ảnh Số Báo Danh (BIB)")]),
                            _c("br"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.window.SERVER_BASE_URL +
                                    "/images/bib-placeholder.jpg",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Tải template")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-12 col-lg-6 col-md-6" },
                                [
                                  _c("my-image", {
                                    model: {
                                      value: _vm.form.bib,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "bib", $$v)
                                      },
                                      expression: "form.bib",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-sm-12 col-lg-6 col-md-6" },
                                [
                                  _c("canvas", {
                                    ref: "canvas-bib",
                                    staticStyle: { "max-width": "300px" },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [
                            _c("b", [_vm._v("Certificate")]),
                            _c("br"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.window.SERVER_BASE_URL +
                                    "/images/cert-placeholder.jpg",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Tải template")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-12 col-lg-6 col-md-6" },
                                [
                                  _c("my-image", {
                                    model: {
                                      value: _vm.form.certificate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "certificate", $$v)
                                      },
                                      expression: "form.certificate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-sm-12 col-lg-6 col-md-6" },
                                [
                                  _c("canvas", {
                                    ref: "canvas-cert",
                                    staticStyle: { "max-width": "300px" },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("v-tab", { attrs: { title: "Về cuộc đua" } }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  _vm._l(_vm.introduceGroup, function (group, index) {
                    return _c("form-card", {
                      key: index,
                      attrs: { group: group },
                      model: {
                        value: _vm.form,
                        callback: function ($$v) {
                          _vm.form = $$v
                        },
                        expression: "form",
                      },
                    })
                  }),
                  1
                ),
              ]),
            ]),
            _c("v-tab", { attrs: { title: "Quỹ từ thiện" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Quỹ từ thiện")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.charities,
                                placeholder: "Chọn quỹ từ thiện...",
                              },
                              model: {
                                value: _vm.form.charity_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "charity_id", $$v)
                                },
                                expression: "form.charity_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Số tiền đóng góp")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "0" },
                              model: {
                                value: _vm.form.charity_money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "charity_money", $$v)
                                },
                                expression: "form.charity_money",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("v-tab", { attrs: { title: "Thông tin khác" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body form-card" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Min Pace")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "0" },
                              model: {
                                value: _vm.form.min_pace,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "min_pace", $$v)
                                },
                                expression: "form.min_pace",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Max Pace")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-10 col-md-10" },
                          [
                            _c("el-input", {
                              attrs: { type: "number", placeholder: "0" },
                              model: {
                                value: _vm.form.max_pace,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "max_pace", $$v)
                                },
                                expression: "form.max_pace",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Địa điểm offline")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.locations,
                                multiple: true,
                                placeholder: "Chọn địa điểm...",
                              },
                              model: {
                                value: _vm.form.locations,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "locations", $$v)
                                },
                                expression: "form.locations",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Tiện ích")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.addons,
                                multiple: true,
                                placeholder: "Chọn tiện ích...",
                              },
                              model: {
                                value: _vm.form.addons,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "addons", $$v)
                                },
                                expression: "form.addons",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Quy định")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("my-select", {
                              staticClass: "full-width",
                              attrs: {
                                attribute: _vm.rules,
                                multiple: true,
                                placeholder: "Chọn quy định...",
                              },
                              model: {
                                value: _vm.form.rules,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "rules", $$v)
                                },
                                expression: "form.rules",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Có huy chương thật")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_have_medal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_have_medal", $$v)
                                },
                                expression: "form.is_have_medal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Có huy chương điện tử")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_have_medal_online,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "is_have_medal_online",
                                    $$v
                                  )
                                },
                                expression: "form.is_have_medal_online",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Có giấy chứng nhận")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_have_certificate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_have_certificate", $$v)
                                },
                                expression: "form.is_have_certificate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Hiển thị số BIB")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_show_bib,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_show_bib", $$v)
                                },
                                expression: "form.is_show_bib",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                          [_c("b", [_vm._v("Chia CLB theo bảng")])]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-10" },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.is_show_group_club,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "is_show_group_club", $$v)
                                },
                                expression: "form.is_show_group_club",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.is_show_group_club,
                              expression: "form.is_show_group_club",
                            },
                          ],
                          staticClass: "row",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-lg-2 col-md-2" },
                            [_c("b", [_vm._v("Chọn danh sách bảng")])]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-md-10" },
                            [
                              _c("my-select", {
                                staticClass: "full-width",
                                attrs: {
                                  attribute: _vm.groups,
                                  multiple: true,
                                  placeholder: "Chọn danh sách bảng",
                                },
                                model: {
                                  value: _vm.form.group_clubs,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "group_clubs", $$v)
                                  },
                                  expression: "form.group_clubs",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "page-action" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [
                _vm._v("Quay lại"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("Cập nhật")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }