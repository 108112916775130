export default [
  // 0.Hình ảnh
  {
    groups: [
      {
        attributes: [
          {
            prop: 'image',
            label: 'Ảnh cover',
            type: 'image'
          },
          {
            prop: 'badge',
            label: 'Medal',
            type: 'image'
          },
          {
            prop: 'certificate',
            label: 'Certificate',
            type: 'image'
          },
        ]
      }
    ]
  },
  // 1.Về cuộc đua
  {
    groups: [
      {
        attributes: [
          {
            prop: 'description_vi',
            label: 'Mô tả ngắn',
            type: 'textarea'
          },
          {
            prop: 'description_en',
            label: 'Mô tả ngắn (en)',
            type: 'textarea'
          },
          {
            prop: 'vi_introduce',
            label: 'Giới thiệu',
            type: 'editor'
          },
          {
            prop: 'en_introduce',
            label: 'Giới thiệu (en)',
            type: 'editor'
          },
          {
            prop: 'vi_award',
            label: 'Giải thưởng',
            type: 'editor'
          },
          {
            prop: 'en_award',
            label: 'Giải thưởng (en)',
            type: 'editor'
          },
          {
            prop: 'vi_title_seo',
            label: "Tiêu đề SEO",
            type: 'text'
          },
          {
            prop: 'en_title_seo',
            label: "Tiêu đề SEO (en)",
            type: 'text'
          },
          {
            prop: 'vi_description_seo',
            label: "Mô tả SEO",
            type: 'textarea'
          },
          {
            prop: 'en_description_seo',
            label: "Mô tả SEO (en)",
            type: 'textarea'
          },
        ]
      }
    ]
  }
]
