<template lang="html">
  <div class="row">
    <div class="col-sm-12">
      <vue-tabs>
        <v-tab title="Thông tin chung">
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div class="card">
                <div class="card-body form-card">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Tên cuộc đua</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="Tên cuộc đua"
                        v-model="form.vi_title"
                        :class="errors.has('vi_title')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="vi_title"
                        data-vv-as="Tên cuộc đua"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('vi_title')">{{ errors.first('vi_title') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Tên cuộc đua (en)</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="Tên cuộc đua (en)"
                        v-model="form.en_title"
                        :class="errors.has('en_title')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="en_title"
                        data-vv-as="Tên cuộc đua (en)"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('en_title')">{{ errors.first('en_title') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>SKU</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="SKU"
                        v-model="form.sku"
                      ></el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Đường dẫn</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="duong-dan"
                        v-model="form.slug"
                        :class="errors.has('slug')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="slug"
                        data-vv-as="Đường dẫn"
                      >
                        <template slot="prepend">https://irace.vn/races/</template>
                      </el-input>
                      <span class="text-danger" v-if="errors.has('slug')">{{ errors.first('slug') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Địa điểm</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="Địa điểm"
                        v-model="form.vi_address"
                        :class="errors.has('vi_address')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="vi_address"
                        data-vv-as="Địa điểm"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('vi_address')">{{ errors.first('vi_address') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Địa điểm (en)</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="Địa điểm (en)"
                        v-model="form.en_address"
                        :class="errors.has('en_address')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="en_address"
                        data-vv-as="Địa điểm (en)"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('en_address')">{{ errors.first('en_address') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Kiểu</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="raceTypes"
                        placeholder="Chọn kiểu..."
                        v-model="form.type_id"
                        :class="errors.has('type_id')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="type_id"
                        data-vv-as="Kiểu"
                      >
                      </my-select>
                      <span class="text-danger" v-if="errors.has('type_id')">{{ errors.first('type_id') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Thể loại</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="raceCategories"
                        placeholder="Chọn thể loại..."
                        v-model="form.category_id"
                        :class="errors.has('category_id')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="category_id"
                        data-vv-as="Thể loại"
                      >
                      </my-select>
                      <span class="text-danger" v-if="errors.has('category_id')">{{ errors.first('category_id') }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="form.category_id == 4">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Số ngày</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input type="number" placeholder="Số ngày" v-model="form.days"></el-input>
                    </div>
                  </div>
                  <div class="row" v-if="form.category_id == 4">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Race trước</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="races"
                        placeholder="Chọn race..."
                        v-model="form.required_completed_race">
                      </my-select>
                      <i style="font-size: 12px; color: red;">* Sau khi hoàn thành race này thì mới đăng ký được</i>
                    </div>
                  </div>
                  <div class="row" v-if="form.category_id == 3">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Link đối tác</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input placeholder="Link đối tác" v-model="form.partner_link"></el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Không chọn cự ly</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-switch
                        v-model="form.is_without_catalog"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row" v-if="form.is_without_catalog == 0">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Cự ly</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="raceCatalogs"
                        placeholder="Chọn nhiều cự ly..."
                        v-model="form.catalogs"
                        :class="errors.has('catalogs')?'border-danger':''"
                        v-validate.disable="'required'"
                        data-vv-name="catalogs"
                        data-vv-as="Cự ly"
                      >
                      </my-select>
                      <span class="text-danger" v-if="errors.has('catalogs')">{{ errors.first('catalogs') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Chủ đề</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="raceThemes"
                        placeholder="Chọn nhiều chủ đề..."
                        v-model="form.themes"
                      >
                      </my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Đăng ký tối đa</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        type="number"
                        placeholder="Đăng ký tối đa"
                        v-model="form.limit"
                        :class="errors.has('price')?'border-danger':''"
                        v-validate="'required|min_value:0'"
                        data-vv-name="limit"
                        data-vv-as="Đăng ký tối đa"
                      ></el-input>
                      <span class="text-danger" v-if="errors.has('limit')">{{ errors.first('limit') }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Người đăng ký ảo</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        type="number"
                        placeholder="Người chơi ảo"
                        v-model="form.virtual_user"
                      ></el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Miễn phí vận chuyển</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-switch
                        v-model="form.is_free_ship"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row" v-if="form.is_free_ship == 0">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b class="required">Phí vận chuyển</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input
                        placeholder="Phí vận chuyển"
                        v-model="form.shipping_fee"
                        type="number"
                        :class="errors.has('shipping_fee')?'border-danger':''"
                        v-validate="'required|min_value:1'"
                        data-vv-name="shipping_fee"
                        data-vv-as="Phí vận chuyển"
                      >
                      </el-input>
                      <span class="text-danger" v-if="errors.has('shipping_fee')">{{ errors.first('shipping_fee') }}</span>
                    </div>
                  </div>
                  <div class="row" style="display: none;">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Hashtag</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-tag
                        v-model="form.tag">
                      </my-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
              <div class="card">
                <div class="card-body form-card">
                  <div class="row">
                    <div class="col-sm-12">
                      <b class="required">Thời gian kết thúc khắc tên</b>
                    </div>
                    <div class="col-sm-12">
                      <el-date-picker
                        class="full-width"
                        v-model="form.engraved_at"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        :class="errors.has('engraved_at')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="engraved_at"
                        data-vv-as="Thời gian kết thúc khắc tên"
                      >
                      </el-date-picker>
                      <span class="text-danger" v-if="errors.has('engraved_at')">{{ errors.first('engraved_at') }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="form.category_id != 4">
                    <div class="col-sm-12">
                      <b class="required">Thời gian kết thúc đăng ký</b>
                    </div>
                    <div class="col-sm-12">
                      <el-date-picker
                        class="full-width"
                        v-model="form.register_at"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        :class="errors.has('register_at')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="register_at"
                        data-vv-as="Thời gian kết thúc đăng ký"
                      >
                      </el-date-picker>
                      <span class="text-danger" v-if="errors.has('register_at')">{{ errors.first('register_at') }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="form.category_id != 4">
                    <div class="col-sm-12">
                      <b class="required">Thời gian bắt đầu</b>
                    </div>
                    <div class="col-sm-12">
                      <el-date-picker
                        class="full-width"
                        v-model="form.start_at"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        :class="errors.has('start_at')?'border-danger':''"
                        v-validate="'required'"
                        data-vv-name="start_at"
                        data-vv-as="Thời gian bắt đầu"
                      >
                      </el-date-picker>
                      <span class="text-danger" v-if="errors.has('start_at')">{{ errors.first('start_at') }}</span>
                    </div>
                  </div>
                  <div class="row" v-if="form.category_id != 4">
                    <div class="col-sm-12">
                      <b class="required">Thời gian kết thúc</b>
                    </div>
                    <div class="col-sm-12">
                      <el-date-picker
                        class="full-width"
                        v-model="form.end_at"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày giờ"
                        :class="errors.has('end_at')?'border-danger':''"
                        v-validate="'required|earlier'"
                        data-vv-name="end_at"
                        data-vv-as="Thời gian kết thúc"
                      >
                      </el-date-picker>
                      <span class="text-danger" v-if="errors.has('end_at')">{{ errors.first('end_at') }}</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Cut off time</label>
                    <el-input placeholder="Nhập số phút" v-model="form.cut_off_time">
                    </el-input>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <b>Nổi bật</b>
                    </div>
                    <div class="col-sm-12">
                      <el-switch
                        v-model="form.hot"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <b>Hoàn thành trong 1 lần</b>
                    </div>
                    <div class="col-sm-12">
                      <el-switch
                        v-model="form.is_one_lap"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <b>Unlisted</b>
                    </div>
                    <div class="col-sm-12">
                      <el-switch
                        v-model="form.unlisted"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>
        <v-tab title="Combo">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Combo</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <el-switch
                        v-model="form.is_combo"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row" v-show="form.is_combo">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Chọn race</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="races"
                        :multiple="true"
                        placeholder="Chọn race..."
                        v-model="form.race_ids">
                      </my-select>
                    </div>
                  </div>
                  <div class="row" v-if="form.race_ids && form.race_ids.length > 1">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Giảm giá</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <div v-for="(item, index) of form.race_ids">
                        <div class="row" v-if="index < form.race_ids.length - 1">
                          <div class="col-4">Giảm giá khi mua {{ index + 2 }} race (%):</div>
                          <div class="col-8">
                            <el-input placeholder="5%" type="number" v-model="combo_discounts[index + 2]"></el-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>
        <v-tab title="Giá">
          <div class="card">
            <div class="card-body form-card">
              <div class="row">
                <div class="col-sm-12 col-lg-2 col-md-2">
                  <b>Miễn phí</b>
                </div>
                <div class="col-sm-12 col-lg-10 col-md-10">
                  <el-switch
                    v-model="form.is_free"
                    :active-value="1"
                    :inactive-value="0">
                  </el-switch>
                </div>
              </div>
              <div v-if="form.is_free == 0" class="row">
                <div class="col-2">
                  <b>Kiểu giá</b>
                </div>
                <div class="col-10">
                  <el-radio-group v-model="prices.type">
                    <el-radio-button label="one">Một giá</el-radio-button>
                    <el-radio-button label="many">Nhiều giá</el-radio-button>
                    <el-radio-button label="catalog">Giá theo cự ly</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-show="prices.type == 'one'">
            <div class="card-body form-card">
              <div class="row">
                <div class="col-12">
                  <b class="catalog-title">Một giá</b>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <el-row :gutter="20">
                    <el-col :span="6">Giá bán</el-col>
                    <el-col :span="18">
                      <el-input placeholder="Giá bán" v-model="prices.one.standard" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
                <div class="col-6">
                  <el-row :gutter="20">
                    <el-col :span="6">Giá so sánh</el-col>
                    <el-col :span="18">
                      <el-input placeholder="Giá so sánh" v-model="prices.one.compare" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-show="prices.type == 'many'">
            <div class="card-body form-card">
              <div class="row">
                <div class="col-12">
                  <b class="catalog-title">Nhiều giá</b>
                </div>
              </div>
              <div class="row">
                <div class="col-2">Super early bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-input placeholder="Giá Super early bird" v-model="prices.many.super_early_bird" type="number"></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.start_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.end_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="row">
                <div class="col-2">Early bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-input placeholder="Giá Early bird" v-model="prices.many.early_bird" type="number"></el-input>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.start_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="8">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.many.end_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="row">
                <div class="col-2">Standard</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-input placeholder="Giá Standard" v-model="prices.many.standard" type="number"></el-input>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-show="prices.type == 'catalog'">
            <div class="card-body form-card">
              <div class="row">
                <div class="col-12">
                  <b class="catalog-title">Giá theo cự ly</b>
                </div>
              </div>
              <div class="row">
                <div class="col-2">Chọn cự ly</div>
                <div class="col-7">
                  <my-select
                    class="full-width"
                    :attribute="raceCatalogs"
                    placeholder="Chọn nhiều cự ly..."
                    v-model="form.catalogs"
                  >
                  </my-select>
                </div>
              </div>
              <div class="row">
                <div class="col-2">Super Early Bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.start_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="12">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.end_at_super_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="row">
                <div class="col-2">Early Bird</div>
                <div class="col-10">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.start_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày bắt đầu"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="12">
                      <el-date-picker
                        class="full-width"
                        v-model="prices.catalog.end_at_early_bird"
                        value-format="yyyy/MM/dd H:m:s"
                        type="datetime"
                        placeholder="Chọn ngày kết thúc"
                      >
                      </el-date-picker>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <hr>
              <div v-if="catalogSelectedPrice && catalogSelectedPrice.length" class="list-price-catalog">
                <div v-for="item of catalogSelectedPrice" class="row">
                  <div class="col-2">{{ item.title }}</div>
                  <div class="col-10">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <el-input placeholder="Super Early Bird" v-model="item.super_early_bird" type="number"></el-input>
                      </el-col>
                      <el-col :span="8">
                        <el-input placeholder="Early Bird" v-model="item.early_bird" type="number"></el-input>
                      </el-col>
                      <el-col :span="8">
                        <el-input placeholder="Standard" v-model="item.standard" type="number"></el-input>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>
        <v-tab title="Hình ảnh">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Ảnh cover</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <my-image
                        v-model="form.image"
                      >
                      </my-image>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Huy chương (Medal)</b>
                      <br />
                      <a :href="window.SERVER_BASE_URL + '/images/medal-01.png'" target="_blank">Tải template 01</a>
                      <br />
                      <a :href="window.SERVER_BASE_URL + '/images/medal-02.png'" target="_blank">Tải template 02</a>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <div class="row">
                        <div class="col-sm-12 col-lg-6 col-md-6 p-l-0">
                          <my-image
                            v-model="form.medal"
                          >
                          </my-image>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-md-6">
                          <canvas style="max-width:300px" ref="canvas-medal-deactive"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Ảnh Số Báo Danh (BIB)</b>
                      <br />
                      <a :href="window.SERVER_BASE_URL + '/images/bib-placeholder.jpg'" target="_blank">Tải template</a>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <div class="row">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                          <my-image
                            v-model="form.bib"
                          >
                          </my-image>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-md-6">
                          <canvas style="max-width:300px" ref="canvas-bib"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Certificate</b>
                      <br />
                      <a :href="window.SERVER_BASE_URL + '/images/cert-placeholder.jpg'" target="_blank">Tải template</a>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <div class="row">
                        <div class="col-sm-12 col-lg-6 col-md-6">
                          <my-image
                            v-model="form.certificate"
                          >
                          </my-image>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-md-6">
                          <canvas style="max-width:300px" ref="canvas-cert"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>
        <v-tab title="Về cuộc đua">
          <div class="row">
            <div class="col-sm-12">
              <form-card
              v-for="(group, index) of introduceGroup"
              :key="index"
              :group="group"
              v-model="form"
              ></form-card>
            </div>
          </div>
        </v-tab>

        <v-tab title="Quỹ từ thiện">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Quỹ từ thiện</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="charities"
                        placeholder="Chọn quỹ từ thiện..."
                        v-model="form.charity_id">
                      </my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Số tiền đóng góp</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input type="number" v-model="form.charity_money" placeholder="0"></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Thông tin khác">
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body form-card">
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Min Pace</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input type="number" v-model="form.min_pace" placeholder="0"></el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Max Pace</b>
                    </div>
                    <div class="col-sm-12 col-lg-10 col-md-10">
                      <el-input type="number" v-model="form.max_pace" placeholder="0"></el-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Địa điểm offline</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="locations"
                        :multiple="true"
                        placeholder="Chọn địa điểm..."
                        v-model="form.locations">
                      </my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Tiện ích</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="addons"
                        :multiple="true"
                        placeholder="Chọn tiện ích..."
                        v-model="form.addons">
                      </my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Quy định</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="rules"
                        :multiple="true"
                        placeholder="Chọn quy định..."
                        v-model="form.rules">
                      </my-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Có huy chương thật</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <el-switch
                        v-model="form.is_have_medal"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Có huy chương điện tử</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <el-switch
                        v-model="form.is_have_medal_online"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Có giấy chứng nhận</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <el-switch
                        v-model="form.is_have_certificate"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Hiển thị số BIB</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <el-switch
                        v-model="form.is_show_bib"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Chia CLB theo bảng</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <el-switch
                        v-model="form.is_show_group_club"
                        :active-value="1"
                        :inactive-value="0">
                      </el-switch>
                    </div>
                  </div>
                  <div class="row" v-show="form.is_show_group_club">
                    <div class="col-sm-12 col-lg-2 col-md-2">
                      <b>Chọn danh sách bảng</b>
                    </div>
                    <div class="col-sm-12 col-md-10">
                      <my-select
                        class="full-width"
                        :attribute="groups"
                        :multiple="true"
                        placeholder="Chọn danh sách bảng"
                        v-model="form.group_clubs">
                      </my-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab>

      </vue-tabs>
      <div class="container-fluid">
        <div class="page-action">
          <el-button @click="cancel">Quay lại</el-button>
          <el-button type="primary" @click="save">Cập nhật</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MessageBox, Message, Input, Upload, Table, TableColumn, DatePicker, Button, Switch, CheckboxGroup, Checkbox, Row, Col, Option, Select, RadioGroup, RadioButton } from 'element-ui';
  import MySelect from 'src/components/UIComponents/Select'
  import MyTag from 'src/components/UIComponents/Tag'
  import FormCard from 'src/components/UIComponents/FormCard.vue'
  import Editor from 'src/components/UIComponents/Editor.vue'
  import MyImage from 'src/components/UIComponents/Image'
  import {VueTabs, VTab} from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'
  import dataFrom from './race-form'
  import {mapState} from 'vuex'
  import moment from 'moment'

  let _form = {}, secondGroup;

  export default {
    components: {
      FormCard,
      ElInput: Input,
      ElButton: Button,
      ElUpload: Upload,
      ElTable: Table,
      ElTableColumn: TableColumn,
      ElSwitch: Switch,
      ElDatePicker: DatePicker,
      ElCheckboxGroup: CheckboxGroup,
      ElCheckbox: Checkbox,
      MySelect,
      ElRadioGroup: RadioGroup,
      ElRadioButton: RadioButton,
      ElRow: Row,
      ElCol: Col,
      ElSelect: Select,
      ElOption: Option,
      MyTag,
      Editor,
      VueTabs,
      VTab,
      MyImage
    },

    async beforeCreate() {
      secondGroup = dataFrom[1].groups;
      await this.$store.dispatch('setPageTitle', 'Chỉnh sửa cuộc đua');
      await this.$store.dispatch('fetchRaceTypes');
      await this.$store.dispatch('fetchRaceCategories');
      await this.$store.dispatch('fetchRaceCatalogs');
      await this.$store.dispatch('fetchAddons');
      await this.$store.dispatch('fetchLocations');
      await this.$store.dispatch('fetchCharities');
      await this.$store.dispatch('fetchRules');
      await this.$store.dispatch('fetchThemes');
      await this.$store.dispatch('fetchRaces');
      await this.$store.dispatch('fetchGroups');
      await this.$store.dispatch('setCurrentActions', [{
        label: 'Cập nhật',
        type: 'primary',
        icon: '',
        callback: this.save
      }]);
      let id = this.$route.params.id;
      await this.$store.dispatch('fetchRaceDetail', id);
    },

    created() {
      let self = this;
      this.$validator.extend('earlier', {
        getMessage(field, val) {
          return 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu'
        },
        validate(value, field) {
          let startParts = self.form.start_at
          let momentStart = new Date(startParts).getTime();
          let momentEnd = new Date(value).getTime();
          return momentEnd > momentStart;
        }
      })
    },

    data() {
      return {
        form: {
          medal: '',
          medal_deactive: '',
          is_free: 1
        },
        medalCtx: null,
        combo_discounts: {},
        introduceGroup: secondGroup,
        typeForm: {
          vi_title: '',
          en_title: '',
          slug: ''
        },
        prices: {
          type: '',
          one: {
            standard: 0,
            compare: 0
          },
          many: {
            super_early_bird: 0,
            early_bird: 0,
            standard: 0,
            start_at_super_early_bird: null,
            end_at_super_early_bird: null,
            start_at_early_bird: null,
            end_at_early_bird: null
          },
          catalog: {
            items: [],
            start_at_super_early_bird: null,
            end_at_super_early_bird: null,
            start_at_early_bird: null,
            end_at_early_bird: null
          }
        },
        catalogSelectedPrice: [],
        catalogSelectedPriceTemp: {}
      }
    },

    computed: {
      ...mapState({
        originalForm: state => state.raceDetail,
      }),
      raceTypes() {
        let data = this.$store.state.raceTypes;
        let arr = {};
        arr.options = [];
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      raceCategories() {
        let dat = this.$store.state.raceCategories;
        let arr = {};
        arr.options = [];
        for (let item of dat) {
          arr.options.push({
            value: item.id,
            title: item.name
          });
        }
        return arr;
      },
      raceCatalogs() {
        let data = this.$store.state.raceCatalogs;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.searchable
          });
        }
        return arr;
      },
      raceThemes() {
        let data = this.$store.state.themes;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      addons() {
        let data = this.$store.state.addons;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      locations() {
        let data = this.$store.state.locations;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.name
          });
        }
        return arr;
      },
      groups() {
        let data = this.$store.state.groups;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.name
          });
        }
        return arr;
      },
      races() {
        let data = this.$store.state.races;
        let arr = {};
        arr.options = [];
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      charities() {
        let data = this.$store.state.charities;
        let arr = {};
        arr.options = [{
          title: "Chọn quỹ từ thiện...",
          value: -1
        }];
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      },
      rules() {
        let data = this.$store.state.rules;
        let arr = {};
        arr.options = [];
        arr.multiple = true;
        for (let item of data) {
          arr.options.push({
            value: item.id,
            title: item.title
          });
        }
        return arr;
      }
    },

    methods: {
      date: function (date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
      },
      cancel() {
        this.$router.push({name: 'AllRace'});
      },
      validatePrice() {
        if (this.form.is_free) return 'ok';
        switch (this.prices.type) {
          case 'one':
            let one = this.prices.one;
            if (!one.standard) {
              return 'Vui lòng nhập giá bán';
            }
            break;
          case 'many':
            let many = this.prices.many;
            if (!many.standard) {
              return 'Vui lòng nhập giá bán';
            }
            let check = false;
            if (many.super_early_bird > 0) {
              check = true;
              if (!many.start_at_super_early_bird || !many.end_at_super_early_bird) {
                return 'Vui lòng chọn ngày bắt đầu và kết thúc giá Super early bird';
              }
            }
            if (many.early_bird > 0) {
              check = true;
              if (!many.start_at_early_bird || !many.end_at_early_bird) {
                return 'Vui lòng chọn ngày bắt đầu và kết thúc giá Early bird';
              }
            }
            if (!check) {
              return 'Vui lòng nhập ít nhất 1 giá (Super early bird hoặc Early bird)';
            }
            break;
          case 'catalog':
            let catalog = this.prices.catalog;
            if (!catalog.items || !catalog.items.length) {
              return 'Vui lòng chọn ít nhất 1 cự ly';
            }
            check = true;
            for (let i = 0; i < catalog.items.length; i++) {
              if (!catalog.items[i].standard) {
                check = false;
                break;
              }
            }
            if (!check) {
              return 'Vui lòng nhập giá bán cho từng cự ly';
            }
            break;
          default:
            return 'ok';
        }
        return 'ok';
      },
      save() {
        let self = this;
        let form = this.form;

        let checkPrice = this.validatePrice();
        if (checkPrice != 'ok') {
          return Message({
            message: checkPrice,
            type: 'warning'
          });
        }

        form.prices = this.prices;
        form.combo_discounts = this.combo_discounts;

        if (form.category_id == 4) {
          if (!form.days) {
            return Message({
              message: "Chưa nhập số ngày",
              type: 'warning'
            });
          }
        }

        this.$validator.validateAll().then(function (result) {
          if (!result) {
            return Message({
              message: "Vui lòng nhập đầy thủ thông tin",
              type: 'warning'
            });
          }
          self.$store.dispatch('updateRace', form).then(res => {
            if (res.success) {
              Message({
                message: 'Cập nhật thành công',
                type: 'success'
              });
              setTimeout(() => {
                location.reload();
              }, 1500);
            } else {
              if (res.errors) {
                for (let err in res.errors) {
                  Message({
                    message: res.errors[err][0],
                    type: 'error'
                  });
                }
              } else {
                Message({
                  message: res.message,
                  type: 'error'
                });
              }
            }
          }, error => {
            console.error(error);
          });
        })
      },
      canvasRender(newVal, ref, callback) {
        let self = this;
        let canvas = self.$refs[ref];
        let ctx = canvas.getContext('2d');

        let img = new Image();
        img.onload = function() {
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0);
          callback(canvas, ctx, newVal);
        }
        img.src = newVal;
      }
    },
    watch: {
      originalForm(newVal) {
        this.form = Object.assign({}, newVal);
        this.prices = Object.assign({}, this.prices, this.form.prices);
        this.combo_discounts = Object.assign({}, this.form.combo_discounts);
        if (this.prices.type === 'catalog' && this.prices.catalog) {
          let temp = this.prices.catalog.items.map(a => Object.assign({}, a));
          for (let i of temp) {
            this.catalogSelectedPriceTemp[i.id] = i;
          }
        }
      },
      catalogSelectedPrice(newVal) {
        this.prices.catalog.items = newVal;
      },
      'form.catalogs': function(newVal, oldVal) {
        this.catalogSelectedPrice = [];
        if (this.form.catalogs && this.form.catalogs.length) {
          this.form.catalogs.forEach(item => {
            if (item in this.catalogSelectedPriceTemp) {
              this.catalogSelectedPrice.push(this.catalogSelectedPriceTemp[item])
            } else {
              let index = this.raceCatalogs.options.findIndex(x => x.value == item);
              let title = this.raceCatalogs.options[index].title;
              this.catalogSelectedPrice.push({
                id: item,
                title: title
              })
            }
          });
        }
      },
      'form.medal': function(newVal, oldVal) {
        let self = this;
        let canvas = this.$refs['canvas-medal-deactive'];
        this.$util.canvasRender(newVal, canvas, (canvas, ctx, newVal) => {
          var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          var data = imageData.data;

          for(var i = 0; i < data.length; i += 4) {
            var brightness = (data[i] + data[i + 1] + data[i + 2]) / 3;
            data[i] = brightness;
            data[i + 1] = brightness;
            data[i + 2] = brightness;
          }

          ctx.putImageData(imageData, 0, 0);
          self.form.medal_deactive = canvas.toDataURL();
        });
      },

      'form.engraved_at': function (newVal) {
        if(newVal) this.form.engraved_at = this.date(newVal);
      },

      'form.register_at': function (newVal) {
        if(newVal) this.form.register_at = this.date(newVal);
      },

      'form.start_at': function (newVal) {
        if(newVal) this.form.start_at = this.date(newVal);
      },

      'form.end_at': function (newVal) {
        if(newVal) this.form.end_at = this.date(newVal);
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
      this.$store.state.raceDetail = [];
      this.$store.state.raceTypes = [];
      this.$store.state.raceCategories = [];
      this.$store.state.raceCatalogs = [];
      this.$store.state.addons = [];
      this.$store.state.locations = [];
      this.$store.state.rules = [];
    }
  }
</script>


<style lang="scss">
  .pd-left-0 {
    padding-left: 0 !important;
  }
  .pd-right-0 {
    padding-right: 0 !important;
  }
  .wrapper-prices {
    .catalog-title {
      font-size: 18px;
    }
    .col-child {
      padding: 0 5px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
</style>
